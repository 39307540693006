@import "../scss/variables.scss";

.burbujas {
	.burbuja,
	.burbuja_email,
	.burbuja_wpp {
		position: fixed;
		bottom: 20px;
		right: 20px;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		border: #fff 3px solid;
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: $box_shadow;

		transition: all 0.3s ease;
	}

	.burbuja_wpp {
		background-color: $color_whatsapp_1;
	}

	.burbuja_wpp_open {
		bottom: 100px !important;
	}

	.burbuja_email {
		background-color: $color_email_1;
	}

	.burbuja_email_open {
		bottom: 180px !important;
	}

	.burbuja {
		background-color: $primary_2;

		svg {
			filter: $filter_color_white;
		}
	}

	img {
		height: auto;
		width: 100%;
		filter: $filter_color_white;
		padding: 12px;
	}
}

@import "../scss/variables.scss";

#mapa {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;

    .covertura_lista {
        ul {
            li {
                list-style: none;
                height: 30px;
                border-radius: $border_radius_1;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 0px 10px;
                width: 150px;
            }
            .active {
                background-color: $primary_1;
                color: $white_1;
                font-weight: 700;

                transition: all 0.3s ease;
            }
        }
    }

    .covertura_mapas {
        img {
            width: 600px;
            height: 100%;

            @media (max-width: 700px) {
                width: 400px;
            }

            @media (max-width: 500px) {
                width: 300px;
            }

            @media (max-width: 400px) {
                width: 200px;
            }
        }
    }
}

@import "../scss/variables.scss";

.nav {
	width: 100%;
	z-index: 1;
	margin-top: 10px;

	transition: all 0.3s ease;

	.menuNormal {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		margin-right: auto;
		margin-left: auto;
		max-width: 1140px;
		position: static;

		.img1 {
			//filter: $filter_color_primary_2;
			display: flex;
			width: 160px;
			cursor: pointer;
		}
		.img2 {
			display: none;
		}

		div {
			background: none;
			width: 60px;
			height: 50px;
			border: none;
			color: #fff;
			display: flex;
			flex-direction: row;
			padding-left: 1em;
			justify-content: flex-end;
			img {
				filter: $filter_color_white;
				width: 45px;
				color: $white_1;
				height: 45px;
			}

			@media screen and (min-width: $max-width) {
				display: none;
			}
		}

		@media screen and (max-width: $max-width) {
			button {
				display: none;
			}
		}

		ul {
			display: flex;
			flex-direction: row;
			margin-bottom: 0px;
			padding-top: 0px;
			padding-bottom: 0px;
			padding-right: 0px;
			padding-left: 0px;
			height: 100%;

			@media screen and (max-width: $max-width) {
				display: none;
			}

			a {
				text-decoration: none;
				list-style: none;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;

				&:hover {
					hr {
						display: block;
					}
				}

				li {
					font-weight: bold;
					position: relative;
					color: $primary_2;
					padding-left: 1vw;
					padding-right: 1vw;
					text-transform: uppercase;

					hr {
						display: none;
						margin: 0;
						height: 3px;
						color: $primary_1;
						opacity: 1;

						animation-name: hover_page;
						animation-duration: 0.5s;
						animation-timing-function: ease;
					}
				}
			}
		}

		.activeNav {
			hr {
				display: block;
			}
		}
	}

	.menuLateral {
		position: fixed;
		z-index: 10;
		background-color: $primary_2;
		width: 60%;
		height: 100%;
		right: 0;
		animation-name: menuLateral_1;
		animation-duration: 0.8s;
		animation-timing-function: ease;

		ul {
			padding: 0;
			margin: 0;

			div {
				background: none;
				width: 70px;
				border: none;
				color: #fff;
				padding-left: 1em;
				cursor: pointer;
				img {
					filter: $filter_color_white;
					width: 45px;
					color: $white_1;
					height: 45px;
				}
			}

			li {
				list-style: none;
				display: flex;
				flex-direction: column;

				button {
					margin: 0px 50px 0px 50px;
					height: 50px;
					padding: auto;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				a {
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					width: 100%;
					height: 100%;
					color: $white_1;
					height: 4em;
					display: flex;
					justify-content: center;
					align-items: center;

					&:hover {
						background-color: $primary_1;
					}
				}
			}
		}
	}

	.fondoNegro {
		background-color: $black_1;
		position: fixed;
		height: 100%;
		width: 100%;
		opacity: 0.3;
	}
}

.nav_with_scroll {
	background-color: $primary_2;
	position: fixed;
	width: 100%;
	top: 0px;
	z-index: 1;

	transition: all 0.3s ease;

	.menuNormal {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		margin-right: auto;
		margin-left: auto;
		max-width: 1140px;
		position: static;

		.img2 {
			//filter: $filter_color_white;
			display: flex;
			width: 160px;
			cursor: pointer;
			filter: hue;
		}
		.img1 {
			display: none;
		}

		div {
			background: none;
			width: 60px;
			height: 50px;
			border: none;
			color: #fff;
			display: flex;
			flex-direction: row;
			padding-left: 1em;
			justify-content: flex-end;
			img {
				filter: $filter_color_white;
				width: 45px;
				color: $white_1;
				height: 45px;

				position: absolute;
				right: 0px;
				margin-right: 10px;
				padding: 3px;
			}

			@media screen and (min-width: $max-width) {
				display: none;
			}
		}

		@media screen and (max-width: $max-width) {
			button {
				display: none;
			}
		}

		ul {
			display: flex;
			flex-direction: row;
			margin-bottom: 0px;
			padding-top: 0px;
			padding-bottom: 0px;
			padding-right: 0px;
			padding-left: 0px;
			height: 100%;

			@media screen and (max-width: $max-width) {
				display: none;
			}

			a {
				text-decoration: none;
				list-style: none;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;

				&:hover {
					hr {
						display: block;
					}
				}

				li {
					font-weight: bold;
					position: relative;
					color: $white_1;
					padding-left: 1vw;
					padding-right: 1vw;
					text-transform: uppercase;

					hr {
						display: none;
						margin: 0;
						height: 3px;
						color: $primary_1;
						opacity: 1;

						animation-name: hover_page;
						animation-duration: 0.5s;
						animation-timing-function: ease;
					}
				}
			}
		}

		.activeNav {
			hr {
				display: block;
			}
		}
	}

	.menuLateral {
		position: fixed;
		z-index: 10;
		background-color: $primary_2;
		width: 60%;
		height: 100%;
		right: 0;
		animation-name: menuLateral_1;
		animation-duration: 0.8s;
		animation-timing-function: ease;

		ul {
			padding: 0;
			margin: 0;

			div {
				background: none;
				width: 70px;
				border: none;
				color: #fff;
				padding-left: 1em;
				cursor: pointer;
				img {
					filter: $filter_color_white;
					width: 45px;
					color: $white_1;
					height: 45px;
				}
			}

			li {
				list-style: none;
				display: flex;
				flex-direction: column;

				button {
					margin: 0px 50px 0px 50px;
					height: 50px;
					padding: auto;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				a {
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					width: 100%;
					height: 100%;
					color: $white_1;
					height: 4em;
					display: flex;
					justify-content: center;
					align-items: center;

					&:hover {
						background-color: $primary_1;
					}
				}
			}
		}
	}

	.fondoNegro {
		background-color: $black_1;
		position: fixed;
		height: 100%;
		width: 100%;
		opacity: 0.3;
	}
}

@keyframes menuLateral_1 {
	0% {
		right: -100%;
	}

	100% {
		right: 0;
	}
}

@keyframes hover_page {
	0% {
		width: 0%;
	}

	100% {
		width: 100%;
	}
}

#servicios{
    h2{
        height: 100%;
        text-align: center;
        margin-top: 70px;
    }
}

#inicio{
    .inicio_page{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
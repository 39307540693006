@import "../scss/variables.scss";

.footer {
    background-color: $primary_2;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    color: $white_1;
    padding: 20px;
    margin-top: 50px;

    @media screen and (max-width: $max-width) {
        display: flex;
        flex-direction: column;
    }

    a {
        color: $white_1;
        margin: 4px;
    }
    h4 {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    p {
        text-align: justify;
    }
    .contFooter {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        justify-content: space-around;
        justify-content: flex-start;

        img {
            width: auto;
            height: 3em;
            margin-top: 5px;
        }

        .logoImg {
            text-align: center;
            img {
                //filter: $filter_color_white;
                width: auto;
                height: 5em;
            }
        }
    }
}

@import "../scss/variables.scss";

@mixin btn() {
    border: 2px solid;
    border-radius: $border_radius_btn;
    padding: 0.7em;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
    position: sticky;
    transition: all 0.3s ease-in-out;

    a {
        text-decoration: none;
        color: $white_1;
        cursor: pointer;
    }
}


.btn_1 {
    @include btn();
    border-color: $primary_1;
    color: $primary_1;
    font-weight: 700;
    background: none;

    &:hover {
        border: 2px solid $white_1;
        background-color: $primary_1;
        color: $white_1;
    }

}



.btn_2 {
    @include btn();
    background-color: $primary_1 !important;
    color: $white_1;
    font-weight: 700;
    background: none;
    height: 40px;

    &:hover {
        background-color: $scundary !important;
        height: 50px;
    }
}

.btn_3 {
    @include btn();
    border-color: $scundary_2;
    color: $scundary_2;
    font-weight: 700;
    background: none;

    &:hover {
        border: 2px solid $white_1;
        background-color: $scundary_2 !important;
        color: $white_1;
    }
}

.btn_whatapp {
    @include btn();
    color: $white_1;
    background-color: $color_whatsapp_1 !important;

    &:hover {
        background-color: $color_whatsapp_2 !important;
    }
}

.btn_email {
    @include btn();
    color: $white_1;
    background-color: $color_email_1 !important;

    &:hover {
        background-color: $color_email_2 !important;
    }
}

.btn_consultar_guia {
    @include btn();

    border-color: $primary_1;
    color: $primary_1;
    font-weight: 700;
    background: none;
    background-color: $primary_1;
    color: $white_1;
    font-size: 30px;
    display: flex;
    position: fixed;
    bottom: 23px;
    right: 40%;
    height: 80px;
    cursor: pointer;
    padding: 0.5em;

    @media screen and (max-width: $max-width) {
        border-color: $primary_1;
        color: $primary_1;
        font-weight: 700;
        background: none;
        background-color: $primary_1;
        color: $white_1;
        font-size: 19px;
        display: flex;
        position: fixed;
        bottom: 23px;
        right: 93px;
        height: 64px;
        padding: 14px 13px;
    }

    img {
        height: 30px;
        margin-right: 5px;
    }

    &:hover {
        border: 2px solid $white_1;
        background-color: $primary_1;
        color: $white_1;
    }
}
@import "../scss/variables.scss";

.contacto_cont {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bienvenida_contacto {
        width: 100%;
        height: 400px;
        display: grid;

        background-image: url("../../assets/contacto.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $bg_color_2;

        @media screen and (min-width: 720px) {
            background-size: contain;
        }

        justify-items: center;
        align-items: end;

        p {
            width: 300px;
            h2 {
                text-align: end !important;
                color: $white_1;
                background-color: $white_1;
                color: $primary_2;
                display: inline-block;
                padding: 2px 10px;
                border-radius: $border_radius_1;
                margin: 2px;
                max-width: 310px;
            }
        }
    }

    .cont_formulario {
        padding-top: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        width: 100%;
        margin-bottom: 130px;

        @media screen and (max-width: $max-width) {
            grid-template-columns: 1fr;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 300px;

            label {
                margin: 10px 0px 5px 0px;
            }
            small {
                color: red;
            }

            input,
            textarea {
                border-radius: $border_radius_1;
                padding: 10px 15px;
                border: none;
                box-shadow: $box_shadow;
                border: 1px solid $secundary_3;
            }
            textarea {
                min-height: 130px;
                border-radius: 20px !important;
            }

            button {
                margin-top: 20px;
            }
        }

        .imagen_form {
            background-image: url("../../assets/contacto2.png");
            background-position: center;
            background-size: auto;
            background-repeat: no-repeat;
            width: 90%;

            @media screen and (max-width: $max_width) {
                display: none;
                width: 0px;
            }
        }
    }
}

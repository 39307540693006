@import "../scss/variables.scss";

.miniServices{
    margin-top: 50px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: $max_width) {
        grid-template-columns: 1fr;
    }
}
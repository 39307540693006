@import "../scss/variables.scss";

.itemContactar{

    *{
        margin: 10px 0px 10px 0px;
    }

    border-radius: $border_radius_1;
    max-width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: $box_shadow;
    padding: 10px 0px 10px 0px;
    margin: 20px;

    img{
        width: 90%;
        height: auto;
        box-shadow: $box_shadow;
        border-radius: $border_radius_1;
    }

    button{
        position: relative;
        padding: 15px 20px;
        box-shadow: $box_shadow;
    }

}
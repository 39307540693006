@import "../scss/variables.scss";

.aliados {
    max-width: $max_width_max_scream;
    p {
        text-align: justify;
    }
    .lista {
        margin-top: 60px;
        h3,
        h2 {
            margin: 20px 0 20px 20px;
            text-decoration: underline;
            color: $primary_2;
        }
        ul,
        div {
            padding: 30px 20px;
            border-radius: $border_radius_1;
            box-shadow: $box-shadow;
            transition: all 0.3s ease;

            li {
                list-style: none;
                margin: 15px 0px;
            }

            &:hover {
                transform: scale(1.02);
                box-shadow: $box-shadow_2;
            }
        }
    }
    .icon1 {
        li::before {
            font-family: "Font Awesome 5 Free";
            content: "\f2bb";
            margin-right: 12px;
        }
    }
    .icon2 {
        li::before {
            font-family: "Font Awesome 5 Free";
            content: "\f2c2";
            margin-right: 12px;
        }
    }
}

@import "../scss/variables.scss";

.nosotros_cont {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bienvenida_nosotros {
        width: 100%;
        height: 400px;
        display: grid;
        background-image: url("../../assets/nosotros.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fefefe;
        justify-content: center;
        align-content: center;

        @media screen and (min-width: 720px) {
            background-size: contain;
        }

        p {
            bottom: -100px;
            position: relative;
            h2 {
                background-color: #fff;
                color: #033e62;
                display: inline-block;
                padding: 7px 22px;
                border-radius: 30px;
                margin: 2px;
                max-width: 310px;
            }
        }
    }
    .nosotros_body {
        .trofeo {
            color: gold;
        }

        .nuetra_empresa,
        .mision,
        .vision,
        .mas_nosotros {
            text-align: center;
            max-width: 900px;
            margin-top: 50px;
            p {
                box-shadow: 0px 0px 30px -25px #000000;
                text-align: justify;
                padding: 30px 20px;
                border-radius: $border_radius_1;
                margin-top: 10px;
            }
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .mision,
        .vision {
            h2,
            h3,
            h4,
            h5 {
                border-bottom: 2px solid $primary_2;
            }
        }

        .mas_nosotros {
            display: grid;
            justify-content: center;
            margin: 100px 0px;
            grid-gap: 30px;
            img {
                width: 350px;
                height: 100%;
                padding: 10px;
                box-shadow: $box_shadow;
                border-radius: $border_radius_1;
            }
            button {
                height: 40px;
            }
        }
    }
}

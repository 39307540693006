@import "../scss/variables.scss";

.contactar {
    padding: 10px 0px 10px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sub {
        box-shadow: $box_shadow;
        border-radius: $border_radius_1;
        padding: 15px;
        margin: 0px 30px 30px 30px;
        max-width: $max_width_max_scream;
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.03);
        }
    }

    h2 {
        color: $primary_2;
    }

    hr {
        color: $primary_2;
        border: 1px solid $primary_2;
        margin: 10px;
        max-width: $max_width_max_scream;
    }

    p {
        text-align: center;
    }

    .itemsContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (max-width: $max-width) {
            flex-direction: column;
        }
    }
}

@import "../scss/variables.scss";

.carousel-caption {
    h3 {
        background-color: $primary_1;
        border-radius: $border_radius_1;
        padding: 5px;
        width: auto;
        opacity: 1;
    }
}

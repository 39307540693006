@import "../scss/variables.scss";

.miniService {
    border-radius: $border_radius_1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: $box_shadow;
    margin-top: 55px;
    text-align: center;
    cursor: pointer;

    @media (max-width: $max_width) {
        margin-top: 15px;
        height: 100px;
        display: grid;
        justify-content: space-evenly;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        justify-items: center;
        align-content: center;
    }

    .imgCircleBg {
        background-color: $primary_2;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -45px;
        box-shadow: $box_shadow;
        border: 3px solid $primary_1;

        transition: all 0.3s ease;

        @media (max-width: $max_width) {
            top: 0;
        }

        &:hover {
            transform: scale(1.1);
        }

        img {
            height: auto;
            width: 100%;
            filter: $filter_color_white;
            padding: 12px;
        }
    }

    h2 {
        position: relative;
        top: -30px;

        @media (max-width: $max_width) {
            top: 0;
        }
    }

    button {
        position: relative;
        top: -15px;
        padding: 15px 20px;
        box-shadow: $box_shadow;

        @media (max-width: $max_width) {
            top: 0;
            display: none;
        }
    }
}

.miniService_phone {
    transition: all 0.3s ease-out;
    overflow: hidden;

    @media (min-width: $max_width) {
        display: none;
    }

    button {
        width: 100%;
    }
}

.active {
    transform: scale(1, 1);
}

.no_active {
    display: none;
    transform: scale(1, 0);
}

@import "../scss/variables.scss";

.oficinas {
    margin-top: 60px;
    .lista_oficinas {
        width: 100%;
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            li {
                list-style: none;
                margin: 5px 5px;
            }
        }
    }

    .oficina_card {
        width: 100%;
        height: 100%;
        min-height: 400px;

        .titulo_oficina {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .nombre_oficina,
            .info_oficina {
                box-shadow: $box_shadow;
                ul {
                    padding: 0px;
                    li {
                        list-style: none;
                    }
                }
            }
            .nombre_oficina {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                .opciones_oficina {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 10px;

                    @media screen and (max-width: $max-width) {
                        grid-template-columns: 1fr;
                        width: 100%;
                    }

                    button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: auto;
                        height: 40px;

                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .nombre_oficina,
        .info_oficina,
        .ubicacion_oficina {
            box-shadow: $box_shadow;
            margin: 10px;
            border-radius: $border_radius_1;
            padding: 15px;
        }

        .ubicacion_oficina {
            min-height: 400px;
        }
    }
}

$primary_1: #ff5b00;
$primary_2: #033e62;
$white_1: #fff;
$black_1: #000;
$scundary: #f27405;
$scundary_2: #0b70af;
$secundary_3: rgba(0, 0, 0, 0.3);
$color_whatsapp_1: #128c7e;
$color_whatsapp_2: #1cc9b5;
$color_email_1: #de4032;
$color_email_2: #e76256;
$bg_color_1: #f6bd0e;
$bg_color_2: #faf8f9;
$max_width: 696px;
$max_width_max_scream: 1024px;
$border_radius_1: 30px;
$border_radius_btn: 30px;
$border_whith_1: 2px;
$box-shadow: 0px 0px 30px -25px #000000;
$box-shadow_2: 0px 0px 30px -15px #000000;

$filter_color_white: invert(100%) sepia(100%) saturate(38%) hue-rotate(321deg) brightness(110%) contrast(110%);
$filter_color_black: invert(0%) sepia(8%) saturate(7500%) hue-rotate(300deg) brightness(103%) contrast(110%);
$filter_color_primary_1: invert(54%) sepia(62%) saturate(6348%) hue-rotate(2deg) brightness(103%) contrast(106%);
$filter_color_primary_2: invert(19%) sepia(17%) saturate(5838%) hue-rotate(179deg) brightness(89%) contrast(98%);

@import "../scss/variables.scss";

.cobertura {
    display: flex;
    justify-content: center;

    transition: all 0.3s ease;

    .carta {
        box-shadow: $box_shadow;
        border-radius: $border_radius_1;
        padding: 15px;
        margin: 0px 30px 30px 30px;
        text-align: justify;
        max-width: $max_width_max_scream;
    }

    h2 {
        color: $primary_2;
        text-align: center;
    }

    hr {
        color: $primary_2;
        border: 1px solid $primary_2;
        margin: 10px;
        max-width: $max_width_max_scream;
    }

    p {
        text-align: justify;
    }

    &:hover {
        transform: scale(1.02);
    }
}

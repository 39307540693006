@import "../scss/variables.scss";

.services {
    margin-top: 20px;
    max-width: $max_width_max_scream;
    .myCard {
        width: auto;
        right: 0;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 20px;
        margin-top: 50px;
        margin-left: 10px;
        margin-right: 10px;
        border-color: black;
        box-shadow: 0px 0px 15px -6px #000000;
        border-radius: $border_radius_1;

        @media screen and (max-width: $max-width) {
            flex-direction: column;
        }

        h1 {
            width: 100%;
            text-align: center;
            text-decoration: underline;
            top: -10px;
            position: relative;
        }

        .imagen {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: $max_width) {
                display: none;
            }
            img {
                width: 300px;
                height: auto;
                box-shadow: 0px 0px 20px -6px #000000;
                position: relative;
                top: -30px;
                left: -30px;
                border-radius: $border_radius_1;
            }
            @media screen and (max-width: $max-width) {
                align-items: center;
            }
        }

        .cardDescription {
            display: flex;
            flex-direction: column;
            p {
                width: 90%;

                @media screen and (max-width: $max-width) {
                    width: 100%;
                    padding: 0;
                }
            }
            .botones {
                display: flex;
                flex-direction: column;
                position: static;
                justify-content: space-between;
                height: 100%;
                .social {
                    display: flex;
                    flex-direction: row;
                    width: 100px;
                    justify-content: space-between;
                }

                .animacion_servicio {
                    width: 100%;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        height: 80%;
                        position: relative;
                        filter: $filter_color_primary_2;

                        animation-name: service_icon;
                        animation-duration: 5s;
                        animation-timing-function: ease;
                        animation-iteration-count: infinite;
                    }
                }
                .saberMas {
                    width: 100%;
                    button {
                        bottom: -30px;
                        float: right;
                        position: relative;
                        box-shadow: $box-shadow;
                        width: 60%;
                    }
                }
            }
        }
    }
}

@keyframes service_icon {
    0% {
        transform: scale(1);
    }
    5% {
        transform: scale(1.1);
    }
    10% {
        transform: scale(1);
    }
}

@import "../scss/variables.scss";

.servicesCont {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bienvenida_servicios {
        width: 100%;
        height: 400px;
        display: grid;

        background-image: url("../../assets/bienvenida.jpg");
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        background-color: $bg_color_1;

        justify-items: center;
        align-items: center;

        p {
            width: 300px;
            h2 {
                text-align: end !important;
                color: $white_1;
                strong{
                    background-color: $white_1;
                    color: $primary_2;
                    border-radius: $border_radius_1;
                    padding: 0px 5px;
                }
            }
        }
    }
}
